<template>
  <div>
    <reminder text="查询微信小程序、H5 微网站客户端页面路径" />
    <h1 class="config-section-title" style="margin-bottom: 20px">
      页面路径查询
    </h1>
    <el-form class="medium-form" size="small" @submit.native.prevent>
      <el-form-item label="页面路径：">
        <el-button plain @click="openDialog('mp')">查看小程序页面</el-button>
        <el-button @click="openDialog('h5')" plain
          >查看 H5 微网站页面</el-button
        >
      </el-form-item>
<!--      <el-form-item label="使用示例：">-->
<!--        <el-image :preview-src-list="[`${admin_url}/app_design/images/set-link-config-path.png`]" :src="`${admin_url}/app_design/images/set-link-config-path.png`" style="width: 500px; height: auto;" fit="contain"/>-->
<!--      </el-form-item>-->
    </el-form>
    <h1
      class="config-section-title"
      style="margin-bottom: 20px; margin-top: 40px"
      v-if="isWxApplet"
    >
      小程序首页短信 URL Link
    </h1>
    <el-form @submit.native.prevent class="medium-form" size="small">
      <el-form-item label=" ">
        <el-button @click="getUrlLink('home')" plain>生成并复制</el-button>
        <p class="info"><i class="el-icon-warning"></i>每条链接自生成时间起，有效期为 30 天</p>
      </el-form-item>
    </el-form>

    <link-config-dialog
      :exclude_requestType="unselectable_type"
      :jump_type="jump_type"
      :requestType="selectable_type"
      platform="mp-weixin"
      readonly
      v-model="showLinkConfigDialog"
      v-show="platform !== 'h5'"
    />
    <link-config-dialog
      :exclude_requestType="unselectable_type"
      :jump_type="jump_type"
      :requestType="selectable_type"
      platform="h5"
      readonly
      v-model="isShowH5LinkDialog"
      v-show="platform === 'h5'"
    />
  </div>
</template>

<script>
import Reminder from '@/modules/common/components/Reminder'
import LinkConfigDialog from '@/modules/app-design/components/Normal/LinkConfigDialog'
import { copyText } from '../../../base/utils/tool'
import {getURLLink} from '../../common/api'
export default {
  components: { LinkConfigDialog, Reminder },
  data() {
    return {
      showLinkConfigDialog: false,
      isShowH5LinkDialog: false,
      selectable_type: [],
      unselectable_type: ['mp_link', 'h5_link', 'phone_link'],
      jump_type: 'page',
      platform: '',
      admin_url: window.serverConfig.VUE_APP_ADMINURL
    }
  },
  computed: {
    isWxApplet() {
      return this.$store.getters.tenant.features.includes('wx_applet')
    },
  },
  methods: {
    getUrlLink(system_page = 'home') {
      if (system_page === 'home') {
        getURLLink('main/pages/splash/splash', '').then(({data, msg}) => {
          copyText(data.data.url_link)
            .then(() => {
              this.$message.success('复制成功')
            })
            .catch((err) => {
              this.$message.error(err.message)
            })
        }).catch(() => {
        })
      }
    },
    openDialog(key) {
      if (key === 'h5') {
        this.platform = key
        this.isShowH5LinkDialog = true
      } else {
        this.platform = ''
        this.showLinkConfigDialog = true
      }
    },
  },
}
</script>
